@font-face {
  font-family: 'Generation';
  src: local('Generation'), url('../../fonts/Generation 1970.ttf') format("truetype");
}
@font-face {
  font-family: 'Generation Light';
  src: local('Generation Light'), url('../../fonts/Generation 1970 Light.ttf') format("truetype");
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');

html,
body,
#root {
  height: 100%;
  margin: 0;
}

.contact-page {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  padding-top: 50px;
  background-color: #F4EAD4;
  color:#202020;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .cross-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
      fill: #202020;
    }
  }
  h1 {
    text-align: center;
    color:#202020;
      font-family: Generation;
      font-size: 54px;
      font-weight: 400;
      margin-block-end:10px;
  }

  .contact-form {
    margin-top: 40px;
    max-width: 720px;
    width:100%
  }

  .row {
    display: flex;
    margin-bottom: 15px;
    .col {
      flex: 1;
      padding-right: 20px;
      
      &:last-child {
        margin-right: 0;
      }
      .submit {
        justify-content: center;
        margin-top:20px;
      }
    }

    &.center {
      justify-content: center;
    }
  }

  input[type='text'],
  textarea {
      font-family: Generation Light;
    width: 100%;
    max-height: 30px;
    height: 100%;
    color:#202020;
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid #B1B1B1;
    font-size: 16px;
    outline: none;
    background-color: #F4EAD4;
    padding: 0 5px 7px;

  }

  input[type='submit'] {
    background: #ff9e63;
    color: #202020;
    font-family:Generation Light ;
    width: 200px;
    border-radius: 6px;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    height: 48px;
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
    padding-top: 6px;
    transition: .25s;
    &:hover {
      color: #FFFFFF;
    }
  }

    .additional-info {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 70px;
  
      div {
        margin-bottom: 10px;
      }
      .footer-box{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 40px;
        border-right: 1px solid #B1B1B1; 
        height: 94px;
        width: 240px;
        &:last-child {
          border-right: none;
          padding: 0 0 0 40px;
        }
        .top-footer{
          font-weight: 400;
          font-size: 10px;
        }
        .bottom-footer{
          font-weight: 600;
          font-size: 12px;
        }
      }
    }

    input[type='text']::placeholder,
    textarea::placeholder {
      color: #202020;
    }

  .title {
    margin-top: 35px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }

   @media (max-width: 480px) {
      .cross-icon {
          top: 10px;
          right: 20px;
      
          img {
            width: 35px;
            height: 35px;
          }
        }
    padding: 0 10px;
.title {
    font-size: 8px;
  }
    input[type='submit'] {
    height: 40px;
    width: 166px;
    font-size: 14px;
    padding-top: 6px;
  }
    h1{
      font-size: 30px;
    }

    .contact-form {
      margin-top: 10px;
      .row {
        padding: 0 35px;
        flex-direction: column;
        margin-bottom: 0px;
        .col {
          
          margin-right: 0;
          margin-top: 15px;
        }
      }
    }

     .additional-info{
      flex-direction: column;
      margin-top: 30px;

      .footer-box{
        border-right: none;
        border-bottom: 1px solid #B1B1B1;
        align-items: center;
        padding-bottom: 20px;
        &:last-child {
            border-bottom: none;
          }
      }

      .bottom-footer {
        text-align: center;
      }
     }
    }
     input[type='text'],
     textarea {
      width: 100%
     }

   
  }

   @media (max-width: 768px) {
   
    
    html,
    body,
    #root {
      height: auto;
      overflow: auto;
    }
  }
