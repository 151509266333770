@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.pin-hotspot {
  transform-origin: center;
  background: linear-gradient(190deg, rgba(196,196,196,1) 0%, rgba(241,232,210,1) 100%);;
  width: 500px;
  height: 350px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  font-family: 'Inter', sans-serif;

  
  animation: 0.3s forwards pin-anim ease-out ;
}

.pint-hotspot-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  color: #4d4d4d;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  height: 100%;
}

.pin-hotspot-text-title {
  font-weight: 700;
  padding: 20px;
  font-family: 'Bauhaus';
  padding-bottom: 3px;
  /* font-size: 20px; */
  font-size: 26px;
  text-transform: uppercase;
}

.pin-hotspot-text-subTitle {
  padding: 20px;
  padding-top: 0;
  font-family: 300;
  opacity: 0.8;
  font-size: 12px;
  font-weight: 600;

  padding-bottom: 20px;
}
.pin-hotspot-text-button {
  margin-top: 20px;
  padding: 6px 20px;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 800;
  border-radius: 5px;
  background-color: #f0b143;
}

.pin-hotspot-text-button-wrapper {
  padding-right: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
  
}

.pin-hotspot-text-text {
  padding: 20px;
  padding-top: 0;
  font-size: 14px;
  font-weight: 300;
  position: relative;

}

.pin-hotspot-image {
  height: 50%;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.pin-hotspot-text {
  position: relative;
  /* font-family: 'Inter', sans-serif; */
  font-family: 'Roboto', sans-serif;


}

@keyframes pin-anim {
  0% {
    transform: translate(-250px, -175px) scale(0) rotateX(-90deg);
  }
  
  30% {
    border-radius: 35%;
  }
  
  100% {
    border-radius: 2%;
    /* border-width: 1px; */
    transform:  translate(-250px, -175px) scale(1) rotateX(0); 
  }
}


.pin-hotspot-image-fake {
  animation: 0.3s forwards pin-image-anim ease-out ;
  
}
@keyframes pin-image-anim {
  0% {
    opacity: 0;
  } 
  
  99% {
    opacity: 0;
  }
  
  99.9% {
    opacity: 1;
  }
}