@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

@font-face {
  font-family: "Generation";
  src: local("Generation"),
    url("../fonts/Generation\ 1970.ttf") format("truetype");
}
@font-face {
  font-family: "Generation Light";
  src: local("Generation Light"),
    url("../fonts/Generation 1970 Light.ttf") format("truetype");
}

.header {
  display: none;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  background: rgba(32, 32, 32, 0.6);
  height: 11%;
  z-index: 10;
  font-family: Generation Light !important;
}

.header-first-col {
  display: flex;
  justify-content: space-between;
}
.header-row {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.header-col {
  display: flex;
  justify-content: space-between;
}

.sign_in {
  display: none;
}

.menu-icon {
  display: none;
}

.header-text {
  font-family: Generation Light !important;
  font-size: 16px;
}
.header_text_small {
  font-size: 14px;
  font-family: "Generation" !important;
}

@media only screen and (max-width: 1024px) {
  .header-col {
    display: none;
  }
  .menu-icon {
    display: flex;
  }
  .header-row {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
  }
  .header_text {
    display: none;
  }
  .sign_in {
    display: flex;
  }
  .header-first-col {
    display: block;
  }
}
