.footer_button-center {
  padding: 20px 30px;
  width: 25%;
}

.footer_button-standart {
  padding: 20px 20px;
}
.search-btn {
  font-size: 25px;
}

.back-arrow {
  margin-left: 10px;
  padding: 20px 30px;
  gap: 5px;
}

.back-arrow:hover > svg > path {
  fill: white;
}

.footer_button-standart:hover > img {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(335deg)
    brightness(106%) contrast(101%);
}

.footer_button {
  pointer-events: auto;
  background: #fdfbf6;
  border-radius: 7px;
  font-size: 1.1em;
  font-weight: 600;
  color: #202020;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 2px solid rgba(0, 0, 0, 0);
  z-index: 10;
}

.footer_button:hover {
  color: #fdfbf6;
  background: #202020;
}
.footer_button:active {
  border: 2px solid #f8f2e5;
}
.footer_button-active {
  color: #fdfbf6;
  background: #202020;
}

@media only screen and (max-width: 540px) {
  .footer_button {
    height: 40px;
    font-size: 2.6vmin;
  }
  .search-btn {
    font-size: 5vmin;
  }
  .footer_button-standart {
    align-items: center;
    justify-content: center;
  }
  .rotate-image {
    width: 5vmin;
    height: 3vmin;
  }
  .back-arrow {
    margin-left: 10px;
  }
  .back-arrow > svg > path {
    fill: black;
  }
}

@media only screen and (max-width: 1024px) {
}
