$main-text-color: #202020;
$white-color: #fdfbf6;
$white-yellow: #f8f2e5;
$light-yellow:#FBF7EE;
$gradient-btn: #ff9e63;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

@font-face {
  font-family: "Generation";
  src: local("Generation"),
    url("./fonts/Generation 1970.ttf") format("truetype");
}
@font-face {
  font-family: "Generation Light";
  src: local("Generation Light"),
    url("./fonts/Generation 1970 Light.ttf") format("truetype");
}

.threeCanvas {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}

.errotPage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $main-text-color;
  color: $white-color;
  font-size: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $main-text-color;
}

html {
  color: $white-color;
}

body * {
  font-family: "Inter", sans-serif;
}

iframe {
  border: none;
  outline: none;
}

body {
  overflow: hidden;
}

button {
  cursor: pointer;
}

.buildingView {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #252934;
}

.ant-picker {
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: .25s;
  cursor: pointer;
  height: 40px;
  input {
    cursor: pointer;
  }

  &:hover {
    background: #F0B043 !important;
  }
}

.ant-picker-input {
  input {
    padding-left: 10px !important;
  }
  input, input::placeholder {
    font-weight: 600 !important;
    font-size: 13px !important;
    color: rgba(0, 0, 0, 0.88) !important;
  }
}

 .cross-icon-footer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  align-items: center;
  
   svg {
     width: 14px;
     height: 14px;
     fill: #202020;
      cursor: pointer;
   }
 }

.footer-checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family:"Inter",sans-serif ;
    font-weight: 500;
  label {
    margin-inline-start: 10px !important;
  }

  .ant-checkbox-wrapper {
      &:hover {
        .ant-checkbox-checked {
          opacity: 0.8;
          .ant-checkbox-inner {
              background-color: $gradient-btn !important;
              border-color: $gradient-btn !important;
            }
        }
        .ant-checkbox-inner {
          border-color: rgb(175, 174, 174) 
        }
      }
  }

 

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $gradient-btn;
      border-color: $gradient-btn;
    }
  
  }
}

.price-slider {
  margin:11px 10px;
  .ant-slider-track {
    background-color: $gradient-btn;
  }
  .ant-slider-handle {
    &::after {
      box-shadow: 0 0 0 2px $gradient-btn !important;
    }
  }
 
  &:hover{
    opacity: 0.8;
    .ant-slider-track {
        background-color: $gradient-btn;
      }
    
    .ant-slider-handle {
      &::after {
        box-shadow: 0 0 0 4px $gradient-btn !important;
      }
    }
  }
}


.ant-checkbox.ant-checkbox-checked::after {
  border: 2px solid $gradient-btn !important;
}


.footer {
  pointer-events: none;
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 9;
  pointer-events: none;

  &_button {
    background: $white-color;
    border-radius: 7px;
    font-size: 1.1em;
    font-weight: 600;
    color: $main-text-color;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 2px solid rgba(0, 0, 0, 0);
    z-index: 10;
    pointer-events: auto;
    &:active {
      border: 2px solid $white-yellow;
    }

    &-standart {
      padding: 20px 0px;
    }

    &-center {
      padding: 20px 0px;
      width: 25%;
    }

    &:hover {
      color: $white-color;
      background: $main-text-color;
      & > svg > path {
        fill: $white-color;
      }
      & > img {
        filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(335deg)
          brightness(106%) contrast(101%);
      }
    }
    &-active {
      color: $white-color;
      background: $main-text-color;
    }

    &-zoom {
      font-size: 2em;
      width: 60px;
      height: 60px;
    }

    &-rotate {
      height: 100%;
      padding: 23px;
    }
  }

  &_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    &-center {
      gap: 10px;
    }
    &-rotate {
      gap: 20px;
    }
  }

  &_back {
    font-size: 1em;
  }

  &_search {
    position: fixed;
    left: 60px;
    width: 210px;
    bottom: 30%;
    color: $main-text-color;
    border-radius: 8px;
    background: #fbf7ee;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    pointer-events: auto;

    .ant-select-selector {
      height: auto !important;
      background: $white-color !important;
      font-family: "Inter", sans-serif !important;
      border: none !important;
      box-shadow: none !important;
      padding: 5px 20px !important;
      cursor: pointer;
      filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1)) !important;

      &:hover {
        background: #f0b043 !important;
      }
    }

    .ant-select-selection-item {
      font-weight: 600 !important;
      font-size: 13px;
    }

    .ant-select-arrow > span > svg {
      fill: $main-text-color;
    }

    .ant-select-item-option-selected {
      background: #f0b043 !important;
    }

    &-dropdown {
      background: $white-color;
      border-radius: 5px;
      padding: 10px;
    }

    &-button {
      background: $gradient-btn;
      border: none;
      border-radius: 5px;
      font-size: 1em;
      font-weight: 600;
      color: #202020;
      transition: all 0.3s;
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      &:hover {
        color: #FFFFFF
      }
    }
  }
}

.floorHover {
  background: none;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7;
  user-select: none;
  pointer-events: none;

  &_number {
    font-weight: 300;
    font-family: Generation;
    font-size: 9em;
    user-select: none;
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.47);
  }

  &_info {
    background: $white-color;
    border-radius: 20px;
    width: 100%;
    height: 65%;
    max-width: fit-content;
    max-height: fit-content;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &_img {
    height: 50%;
    display: flex;
    padding: 25px 60px;
    justify-content: center;
    align-items: center;
  }

  &_caption {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $main-text-color;
    font-family: Generation;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 15px;
  }

  &_available {
    color: $main-text-color;
    padding: 10px 10px;
    border-radius: 15px;
    background: $white-yellow;
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 0.9em;
    text-transform: uppercase;
  }
}

.backButton {
  position: fixed;
  left: 30px;
  top: 3%;
  border: none;
  font-weight: 600;
  border-radius: 7px;
  padding: 10px 20px;
  background: $white-color;
  z-index: 7;
  transition: all 0.3s;
  outline: none;
  font-size: 0.9em;
  user-select: none;
  color: #000;

  &:hover {
    color: $white-color;
    background: $main-text-color;
  }
}

.ListView {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $white-color;

  &_slider {
    width: 20%;
    background-color: #fbf7ee;
  }

  &_row {
    &-arrow {
      display: none;
    }
    background: $white-color;
    td {
      border-top: 1px solid #f4ead4 !important;
      &:first-of-type {
        border-image: linear-gradient(to left, #f4ead4 70%, transparent 50%)
          100% 1 !important;
      }
    }
    &-button {
      height: 50px;
      width: 160px;
      outline: none;
      border: none;
      background: #fdfbf6;
      font-weight: 600;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      transition: all 0.3s;
      padding: 16px 30px;

      &:hover {
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
      }
    }

    &-name {
      padding: 0;
      margin: 0;
      padding-left: 40px;
      font-family: Generation Light;
      font-weight: 300;
    }

    &:hover {
      background: $white-yellow;
    }
  }

  .ant-table-cell-row-hover {
    background: $white-yellow !important;
    border-radius: 0 !important;
  }

  &_filter {
    color: $main-text-color;
    display: flex;
    flex-direction: column;
    // height: "89%";
    padding: 20px;

    .ant-select-selector {
      height: auto !important;
      background: $white-color;
      font-family: "Inter", sans-serif !important;
      border: none !important;
      box-shadow: none !important;
      padding: 5px 20px !important;
      cursor: pointer;
      filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1)) !important;

      &:hover {
        background: #f0b043 !important;
      }
    }

    .ant-select-selection-item {
      font-weight: 600 !important;
      font-size: 13px;
    }

    .ant-select-arrow > span > svg {
      fill: $main-text-color;
    }

    .ant-select-item-option-selected {
      background: #f0b043 !important;
    }

    &-caption {
      padding-top: 20px;
      padding-bottom: 30px;
    }
  }

  &_table {
    &-container {
      display: none;
      &-up {
        hr {
          display: none;
        }
      }
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      opacity: 0.5;
    }
    &-col {
      display: "flex";
      justify-content: "flex-start";
      align-items: "center";
    }
    *strong {
      font-size: 12px;
    }

    thead {
      td {
        border-top: 1px solid #f4ead4 !important;
      }
      th {
        background: $white-color !important;
        font-weight: 400 !important;
        border-bottom: none !important;
        border-top: 1px solid #f4ead4 !important;
        &:first-of-type {
          border-image: linear-gradient(to left, #f4ead4 70%, transparent 50%)
            100% 1 !important;
        }
        &::before {
          display: none;
        }
        &:first-of-type {
          padding-left: 56px !important;
        }
      }
    }

    &-sort {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &-header {
      color: $main-text-color;
      padding: 20px 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ant-select-selector {
        height: auto !important;
        background: $white-color !important;
        font-family: "Inter", sans-serif !important;
        border: none !important;
        box-shadow: none !important;
        padding: 5px 20px !important;
        cursor: pointer;
        border: 1px solid #b1b1b1 !important;

        &:hover {
          background: #f0b043 !important;
        }
      }
    }
  }
}

.Header_selector {
  display: none;
  position: absolute;
  z-index: 5;
  gap: 10px;
  left: 5%;
  right: 5%;
  height: 40px;
  margin-top: 20px;
  width: 90%;
  top: 11%;
  &-btn {
    background: none;
    color: inherit;
    padding: none;
    cursor: pointer;
    outline: inherit;
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white-color;
    border-radius: 10px;
    margin: 0;
    border: none;
    &-rotate {
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $white-color;
      border-radius: 10px;
      margin: 0;
      padding: 0;
      border-width: 0;
      transform: rotate(180deg);
    }
  }

  &-numberContainer {
    display: flex;
    color: $main-text-color;
    font-family: Generation;
    font-size: 16px;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $white-color;
    height: 100%;
    margin: 0;
    padding: 0;
    border-width: 0;
  }
}

* {
  scrollbar-width: auto;
  scrollbar-color: #575757 #ffffff;
}

*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #575757;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.tourIframe {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  margin: 0;
  width: 100% !important;
  height: 100% !important;
  background: $white-yellow;
}

.PlanViewer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $light-yellow;
  z-index: 8;

  &_sider {
    width: 20%; //this
    height: 80%;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  &_floor {
    // background: $white-color;
    // border-radius: 15px;
    height: 25%;
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &-caption {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $main-text-color;
      font-weight: 600;
      font-family: Generation;
      font-size: 1.8em;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &-available {
      color: $main-text-color;
      border-radius: 15px;
      background: $light-yellow;
      margin-bottom: 20px;
      font-size: 1em;
    }

    &-changeButton {
      border: 2px solid rgba(77, 77, 77, 0.3);
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      aspect-ratio: 1/1;
      font-size: 1.5em;
      outline: none;
      border-radius: 100%;
      transition: all 0.3s;

      &:hover {
        background: rgba(77, 77, 77, 0.3);
        border: none;
      }
    }
  }

  &_infoContainer {
    position: fixed;
  }

  &_roomInfo {
    height: fit-content;
    width: fit-content;
    min-width: 200px;
    padding: 10px 20px;
    background: $white-color;
    pointer-events: none;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $main-text-color;
    gap: 2px;

    &-caption {
      font-size: 1em;
      font-weight: 700;
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 0.75em;
      font-weight: 700;
      height: 30px;
    }

    &-price {
      padding: 10px 10px;
      border-radius: 15px;
      font-size: 0.9em;
      background: $white-yellow;
      font-weight: 600;
    }
    &-view {
      display: none;
    }
  }

  &_plan {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    position: relative;
    height: 70%;
  }

  &_planSvg {
    resize: horizontal;
    overflow: hidden;
    width: 100%;
    height: auto;

    &-room {
      transition: all 0.3s;
      &:hover {
        cursor: pointer;
        fill: #bababa;
      }
    }

    &-available {
      fill: #f0b143 !important;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }

  &_content {
    height: 100%;
    display: flex;
    justify-content: center;
  }
}

.houseLoading {
  position: fixed;
  z-index: 99;
  scale: 1.3;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.header {
  display: none;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  background: rgba(32, 32, 32, 0.6);
  height: 11%;
  z-index: 10;

  &_text {
    font-family: Generation;
    font-size: 16px;
    &_small {
      font-size: 14px;
    }
  }
}

.selectedAmenity {
  transform: translateY(-20px) !important;
  position: fixed;
  height: fit-content;
  width: fit-content;
  padding: 30px;
  background: $white-color;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
  color: $main-text-color;
  user-select: none;
  z-index: 5;
  gap: 15px;

  &::before {
    transform: translateY(-50%);
    content: "";
    position: absolute;
    top: 20px;
    left: -60px;
    height: 2px;
    width: 60px;
    background: $white-color;
    pointer-events: none;
  }

  &::after {
    content: "";
    transform: translateY(-50%);
    position: absolute;
    top: 20px;
    left: -60px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: $white-color;
    border: 2px solid $main-text-color;
    pointer-events: none;
  }

  &_caption {
    font-size: 1.2em;
    font-weight: 700;
  }

  &_info {
    font-size: 0.9em;
  }

  &_button {
    background: $white-color;
    border-radius: 50px;
    font-size: 1em;
    color: $main-text-color;
    transition: all 0.3s;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 2px solid $main-text-color;
    padding: 15px 100px;

    &:hover {
      border: 2px solid rgba(0, 0, 0, 0);
      background: $main-text-color;
      color: $white-color;
    }

    &:active {
      border: 2px solid $white-color;
    }
  }
}

.PanoViewer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  background: $white-yellow;
  &_close {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    background: $white-color;
    border-radius: 50px;
    font-size: 1em;
    font-weight: 700;
    color: $main-text-color;
    transition: all 0.3s;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 2px solid rgba(0, 0, 0, 0);
    z-index: 12;

    &:hover {
      border: 2px solid rgba(0, 0, 0, 0);
      background: $main-text-color;
      color: $white-color;
    }

    &:active {
      border: 2px solid $white-color;
    }
  }
}

.tooltip-inner {
  /* height: 150px; */
  padding: 1rem 1.5rem;
  position: relative;
  /* width: 150px; */
  /* left: -75px;
  top: -75px; */
  color: #f4ead4;
  background-color: $main-text-color;
  font-size: 1.5rem;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  text-align: center;

  /* animation: TP 0.5s ease-in-out; */
  overflow: hidden;

  /* border-radius: 50%;
  background-color: black;
  position: relative;
  overflow: hidden; */
}

.tooltip-inner > div {
  width: 150px !important;
}

// card

.wrapper-card {
  --font-size-title: 24px;
  --font-size-button: 16px;
  --font-size-small: 12px;
  --font-size-normal: 16px;
  --padding-normal: 5px;
  --padding-side-normal: 15px;
  --padding-large: 3vh;
  --image-height: 125px;
  --bottom-line: 2px solid #f4ead4;

  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  bottom: 30px;
  align-self: flex-start;
  height: 100%;
  padding: 3vw 0 3vw 3vw;
  align-items: flex-end;
  width: 240px;

  padding-left: 100px;
  
  h2,
  span,
  p,
  button {
    margin: 0;
    font-family: "Inter", sans-serif;
  }

  .card-container {
    color: $main-text-color;
    padding: var(--padding-large) calc(var(--padding-large) / 2)
      calc(var(--padding-large) / 2) calc(var(--padding-large) / 2);
    width: 100%;
    
    border-radius: 15px;
    background: #fdfbf6;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.06));
  }

  .card-title,
  .card-apartment,
  .card-area,
  .card-availability,
  .card-prices {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: var(--bottom-line);
    padding: calc(var(--padding-normal) * 2) 0;

    p {
      font-size: var(--font-size-small);
      letter-spacing: 0;
      line-height: var(--font-size-small);
      color: #000000;
      font-weight: 400;
      padding: var(--padding-normal) 0;
    }

    hr {
      width: 1px;
      color: #eeeeee;
      background-color: #eeeeee;
      margin: var(--padding-normal) 0;
    }

    h2 {
      font-size: var(--font-size-title);
      font-weight: 300;
      font-family: Generation;
    }
    span {
      font-size: var(--font-size-normal);
      letter-spacing: 0;
      line-height: 18px;
      font-weight: 600;
      text-align: right;
      padding: var(--padding-normal) 0;
    }
  }
  .card-title {
    padding: 0;
    p {
      text-transform: uppercase;
      padding: 25px 0;
      font-weight: 700;
      letter-spacing: 0.1em;
    }
  }
  .card-apartment {
    div {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
  }
  .card-preview-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: var(--image-height);
      padding: var(--padding-side-normal) 0;
    }
  }
  .card-buttonContainer {

    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    &-download {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: var(--padding-normal);
      margin-top: 1rem;
      button, a {
        color: $main-text-color;
        border: 1px solid rgba($color: #202020, $alpha: 0.2);
        background-color: transparent;
        border-radius: 10px;
        width: 100%;
        padding: var(--padding-side-normal) 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        margin-bottom: var(--padding-normal);
        transition: all 0.3s;
        text-decoration: none;
        text-align: center;

        &:hover {
          background-color: #4d4d4d;
          color: #fdfbf6;
        }
      }
    }

    &-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: var(--padding-normal);
      button {
        color: $main-text-color;
        // background: #f0b043;
        background: $gradient-btn;
        border: 1px solid #cccccc;
        border-radius: 10px;
        width: 100%;
        padding: var(--padding-side-normal) 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: var(--font-size-button);
        cursor: pointer;
        margin-bottom: var(--padding-normal);
        transition: all 0.3s;

        &:hover {
          background-color: #4d4d4d;
          color: #fdfbf6;
        }
      }
    }
  }
}

.Tour {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 200px;
  width: 100%;
  height: 100%;
  color: $main-text-color;
  &-middleContainer {
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: row;
    width: 100%;
    &-img-wrapper {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 500px;
      height: 500px;

      flex: 1 0;
      
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &-btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    padding: 0 20px;

    gap: 10px;
    margin-block: 20px;

    &-backArrow {
      width: 48px;
      height: 48px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border: none;
      background-color: $white-color;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      display: flex;
      transform: rotate(-90deg);
    }
    &-tour {
      height: 48px;
      color: black;
      min-width: 112px;
      border: none;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 10px;
      background-color: $white-color;
      font-size: 12px;
      font-weight: 500;
      :hover {
        background-color: black;
        color: $white-color;
      }
    }
  }
}

@media screen and (max-height: 728px) {
  .wrapper-card {
    
    --image-height: 125px;
    --font-size-title: 20px;
    --font-size-button: 14px;
    --font-size-normal: 12px;
    --padding-normal: 5px;
    --padding-side-normal: 10px;
    --padding-large: 5vh;
  }
}

//

@media only screen and (max-width: 425px) {
  .card-title,
  .card-apartment,
  .card-area,
  .card-availability,
  .card-prices {
    padding: 0;
  }

  

  .wrapper-card {
    width: 90%;
    bottom: 0;
    // height: 100%;
    height: unset;
    margin-left: auto;
    padding-left: 15px ;
    margin-right: auto;
    // --image-height: 125px;
    --font-size-title: 16px;
    --font-size-button: 14px;
    --font-size-normal: 10px;
    --padding-normal: 2px;
    --padding-side-normal: 10px;
    --padding-large: 2vh;
  }
  .card-container {
    margin: 0;
    padding: 0;
  }

  .card-middle {
    display: flex;
    justify-content: space-evenly;
  }

  .Tour {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 200px;
    width: 100%;
    height: 100%;
    gap: 0px;
    color: $main-text-color;
    &-middleContainer {
      display: block;
      align-items: center;
      gap: 0;
      margin: 0;
      padding: 0;
    }
    &-middleContainer {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      &-img-wrapper {
        position: relative;
        left: 0;
        top: 0;
        transform: unset;
        height: 100%;
        width: calc(100% - 40px); 
      }
    }
    &-btnContainer {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 10px;
      margin-block: 20px;

      &-backArrow {
        width: 40px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border: none;
        height: 40px;
        background-color: $white-color;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        display: flex;
        transform: rotate(-90deg);
      }
      &-tour {
        height: 40px;
        color: black;
        min-width: 112px;
        border: none;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 10px;
        background-color: $white-color;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .backButton {
    display: none;
  }

  .Header_selector {
    display: inline-flex;
  }

  .ListView {
    &_row {
      &-name {
        padding: 0;
        margin: 0;
      }
      &-buttonColumn {
        display: none;
      }
      &-arrow {
        display: flex;
        fill: black;
      }
    }

    &_table {
      display: none;

      &-container {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        color: $main-text-color;
        align-items: center;
        justify-content: space-between;
        padding-inline: 20px;
        text-align: left;
        border-top: 1px solid #f4ead4;
        padding-block: 10px;

        &-up {
          max-height: 45vh;
          border-bottom: 1px solid #f4ead4;
          overflow-x: scroll;
          :hover {
            background-color: #f4ead4;
          }
        }

        &-icon {
          transform: rotate(90deg);
        }

        &-name {
          font-size: 16px;
          font-weight: 300;
          font-family: Generation Light;
          margin: 8px;
        }
        &-inner {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        strong {
          font-size: 8px;
          font-weight: 600;
          margin-inline: 5px;
        }
        hr {
          border: none;
          margin: 0;
          padding: 0;
          border-left: 1px solid rgba($color: #202020, $alpha: 0.2);
          height: 12px;
          transform-origin: left top;
        }
        :first-child {
          margin-left: 0;
        }
        :last-child {
          border-right: 0;
          margin-right: 0px;
        }
      }
      &-header {
        display: block;
        justify-content: center;
        align-items: center;
      }
      &-sort {
        margin-top: 20px;
        justify-content: center;
      }
    }
    &_slider {
      display: none; // handle this
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10;
      background-color: rgba($color: #000000, $alpha: 0.3);
      padding: 50px;
    }
    &_filter {
      background-color: #fbf7ee;
      align-self: center;
      border-radius: 15px;
    }
  }

  .footer_search {
    pointer-events: auto;
    left: auto;
  }
  .threeCanvas {
    width: 100%;
  }

  .PlanViewer {
    &_infoContainer {
      display: flex;
    }
    &_roomInfo {
      align-items: center;
      color: $main-text-color;

      &-caption {
        font-size: 12px;
        font-weight: 600;
      }
      &-info {
        font-size: 8px;
        font-weight: 600;
      }

      &-view {
        width: 100%;
        height: 30px;
        justify-content: center;
        align-items: center;
        display: flex;
        background-color: #f0b043;
        border: none;
        border-radius: 10px;
        font-weight: 600;
        font-size: 10px;
        color: $main-text-color;
        cursor: pointer;
        z-index: 10;
        pointer-events: auto;
      }
    }

    &_sider {
      width: 100%;
    }
    &_plan {
      padding: 0;
      width: 100%;
      height: 380px;
    }
    &_floorPlanContainer {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
    &_content {
      width: 100%;
      height: auto;
      align-items: center;
      justify-content: center;
      display: block;
    }
  }
}

@keyframes TP {
  0% {
    width: 0;
  }
  100% {
    width: 150px;
  }
}


.vt-iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.vt-iframe-panel {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  bottom: 20px;
  
  pointer-events: none;

  display: flex;


  &-styles {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  &-button {
    min-width: 160px;
    pointer-events: auto;
    cursor: pointer;
    text-align: center;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px #0000001A;
    transition: all .3s;

    height: 48px;
    max-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 112px;
    border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    background-color: #fdfbf6;
    font-size: 12px;
    font-weight: 500;
    
    &_active {
      background-color: black;
      color: white;
    }
  }

  
  &-back {
    position: absolute;
    left: 25px;
    min-width: 110px;
    bottom: 0;
  }
}

@media only screen and (max-width: 768px) {
  .vt-iframe-panel {
    top: 20px;
    
    display: flex;
  
  
    &-styles {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  
    &-button {
      height: 40px;
    }
  }
}